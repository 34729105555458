import React from "react";
import { Link } from "gatsby";



import TSSCraneStyles from "./tssCrane.module.css";

import TextWrapAroundImg from "../../components/textWrapAroundImg";

import headlineStyles from "../../components/headline.module.css";

import textWrapAroundImgStyles from "../../components/textWrapAroundimg.module.css";
import Head from "../../components/head";

import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulTssCranePage {
      edges {
        node {
          headerImage {
            file {
              url
            }
          }
          headerHeadline {
            headerHeadline
          }
          headerSubheadline {
            headerSubheadline
          }
          section1Headline {
            section1Headline
          }
          section1Paragraph {
            section1Paragraph
          }
          section1Image {
            file {
              url
            }
          }
          section2Headline {
            section2Headline
          }
          section2Image {
            file {
              url
            }
          }
          section2Paragraph {
            section2Paragraph
          }
        }
      }
    }
  }
`;
class TssCrane extends React.Component {
  componentDidMount() {
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      let p1 = v.replace(
        "TSS/Crane",
        "TSS/<span style='font-size:80%;'>Crane</span>"
      );
      el.innerHTML = p1;
    });
  }
  render() {
    return (
      <>
      <Head title="TSS training | Crane"></Head>
        <div
          className={TSSCraneStyles.backgroundImageContainer}
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.props.data.allContentfulTssCranePage.edges[0].node.headerImage.file.url})`,
          }}
        >
          <div>
            <h1 className={TSSCraneStyles.heading}>
              {
                this.props.data.allContentfulTssCranePage.edges[0].node
                  .headerHeadline.headerHeadline
              }
            </h1>
            <p className={TSSCraneStyles.subHeading}>
              {
                this.props.data.allContentfulTssCranePage.edges[0].node
                  .headerSubheadline.headerSubheadline
              }
            </p>
            <Link to={`/donate`} className={TSSCraneStyles.primaryBtn}>
              Help us
            </Link>
          </div>
        </div>

        <div
          className={headlineStyles.headline}
          style={{ backgroundColor: "#fff" }}
        >
          <h2>
            {
              this.props.data.allContentfulTssCranePage.edges[0].node
                .section1Headline.section1Headline
            }
          </h2>
        </div>
        <TextWrapAroundImg
          imgRight={
            this.props.data.allContentfulTssCranePage.edges[0].node
              .section1Image.file.url
          }
          paragraphHeader={false}
          lightBackground={true}
        >
          {" "}
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulTssCranePage.edges[0].node
                .section1Paragraph.section1Paragraph
            }
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Link
              to="/donate"
              style={{
                textDecoration: "none",
                color: "#ba0c2f",
                fontWeight: "600",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              {" "}
              Help us support apprentices{" "}
            </Link>
          </div>
        </TextWrapAroundImg>

        <section
          style={{ backgroundColor: "#fff" }}
          className={textWrapAroundImgStyles.ourResearchSection}
        >
          <div>
            <h2 style={{ fontSize: "22px" }}>
              {
                this.props.data.allContentfulTssCranePage.edges[0].node
                  .section2Headline.section2Headline
              }
            </h2>

            <div>
              <div>
                <img
                  src={
                    this.props.data.allContentfulTssCranePage.edges[0].node
                      .section2Image.file.url
                  }
                />
              </div>

              <p style={{ whiteSpace: "pre-wrap" }}>
                {
                  this.props.data.allContentfulTssCranePage.edges[0].node
                    .section2Paragraph.section2Paragraph
                }
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default TssCrane;
